<div>
    <div id="peja-page-not-found-title">
            <div id="peja-title-and-subtitle-column">
                <h1>Page Not Found</h1>
                <p>Unfortunately, the page you are looking for does not exist.</p>
                <h3>Unless you were looking for a fun fact about Kenya. <br> In this case. <br> You are in the right place! </h3>
                <br>
                <p>{{funFact?.fact ?? "Often referred to as “The Cradle of Humanity,” Kenya holds some of the oldest human fossils ever discovered by paleontologists. Humanity’s earliest ancestors lived in the Olorgesailie region between 1.2 million and 500 thousand years ago in modern-day Kenya. The oldest known human fossil, the Turkana Boy, was found near Lake Turkana in Kenya. He is estimated to be roughly 1.5 million years old."}}</p>
                <br>
                <a href="/">Go Home</a>
            </div>
    </div>
</div>
