import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import FunFact from '../../model/fun_fact';

@Injectable({
  providedIn: 'root',
})
export class FunFactService {
  url = `${environment.apiUrl}/fun_fact`;
  constructor(private http: HttpClient) {}

  getRandomFunFact(): Observable<FunFact> {
    return this.http
      .get<FunFact>(`${this.url}/random`)
      .pipe(catchError(this.handleError<FunFact>('getRandomFunFact', undefined)));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
