<div>

    <div id="peja-membership-management-title">
        <div *ngIf="pejaSubscriber !== undefined">
            <div id="peja-title-and-subtitle-column">
                <h1>Peja Newsletter</h1>
                <p>Welcome Back {{pejaSubscriber.email}}! Your curiosity is a gem. </p>
            </div>
            <app-subscription-form [pejaSubscriber]="pejaSubscriber"
                [managementPath]="managementPath"></app-subscription-form>
        </div>
    </div>
    <div id="peja-membership-management-loader" *ngIf="pejaSubscriber === undefined"
        class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
</div>