<div>

    <div id="peja-membership-welcome-title">
        <div *ngIf="pejaSubscriber !== undefined">
            <div id="peja-title-and-subtitle-column">
                <h1>Welcome to Peja! </h1>
                <h2>What's Next?</h2>
                <p>Peja will send you a curated collection of articles depending on your topics selection every morning.
                    Expect an email from sondersoftwares&#64;gmail.com starting tomorrow. Please check your spam folder
                    if you
                    cannot find your newsletter email in your main inbox. We appreciate your intent to learn about the beautiful
                    country of Kenya! </p>
                    
            </div>
        </div>
    </div>
    <div id="peja-membership-management-loader" *ngIf="pejaSubscriber === undefined"
        class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
</div>