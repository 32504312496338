import { NgFor, NgForOf, NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { FaqsService } from '../domain/services/faqs/faqs.service';
import Faq from '../model/faq';
import { NewlineToBrPipe } from '../domain/pipes/newline-to-br.pipe';

@Component({
  selector: 'app-faqs',
  standalone: true,
  imports: [NgFor, NgForOf, NgIf, NewlineToBrPipe],
  templateUrl: './faqs.component.html',
  styleUrl: './faqs.component.scss'
})
export class FaqsComponent {
  faqs : Faq[] = []

  constructor(
    private faqsService: FaqsService
  ) {}

  ngOnInit() {
    this.faqsService
      .getAllFaqs()
      .subscribe((faqs) => {
        this.faqs = faqs;
      });
  }

}
