import { Injectable } from '@angular/core';
import Topic from '../../model/topic';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TopicsService {
  url = `${environment.apiUrl}/topics`;
  constructor(private http: HttpClient) {}

  getTopics(): Observable<Topic[]> {
    return this.http
      .get<Topic[]>(this.url)
      .pipe(catchError(this.handleError<Topic[]>('getTopics', [])));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
