import { Component } from '@angular/core';
import PejaSubscriber from '../model/subscriber';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../domain/services/subscription.service';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-welcome',
  standalone: true,
  imports: [NgIf],
  templateUrl: './welcome.component.html',
  styleUrl: './welcome.component.scss'
})
export class WelcomeComponent {

  pejaSubscriber: PejaSubscriber | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      var sha256 = params.get('sha256')!;
      this.subscriptionService
        .fetchSubscriber(sha256)
        .subscribe((subscriber) => {
          this.pejaSubscriber = subscriber;
        });
    });
  }

}
