import { Component } from '@angular/core';
import FunFact from '../model/fun_fact';
import { FunFactService } from '../domain/services/fun_fact.service';

@Component({
  selector: 'app-page-not-found',
  standalone: true,
  imports: [],
  templateUrl: './page-not-found.component.html',
  styleUrl: './page-not-found.component.scss'
})
export class PageNotFoundComponent {
  funFact: FunFact | undefined

  constructor(
    private funFactService: FunFactService
  ) {}

  ngOnInit() {
    this.funFactService
      .getRandomFunFact()
      .subscribe((funFact) => {
        this.funFact = funFact;
      });
  }

}
