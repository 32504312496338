import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import Faq from '../../../model/faq';
import { catchError, Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class FaqsService {
  url = `${environment.apiUrl}/faq`;
  constructor(private http: HttpClient) {}

  getAllFaqs(): Observable<Faq[]> {
    return this.http
      .get<Faq[]>(this.url + "/all")
      .pipe(catchError(this.handleError<Faq[]>('getAllFaqs', [])));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
