import { Component, Input } from '@angular/core';
import { CommonModule, NgFor, NgForOf } from '@angular/common';
import { Router } from '@angular/router';
import { TopicsService } from '../domain/services/topics.service';
import Topic from '../model/topic';
import { SubscriptionService } from '../domain/services/subscription.service';
import PejaSubscriber from '../model/subscriber';
import {
  FormBuilder,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { SubscriberStatus } from '../model/subscriber_status';

@Component({
  selector: 'app-subscription-form',
  standalone: true,
  imports: [NgFor, NgForOf, CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './subscription-form.component.html',
  styleUrl: './subscription-form.component.scss',
})
export class SubscriptionFormComponent {
  topics: Topic[] = [];
  selectedTopics: Topic[] = [];
  failedFields: string[] = [];
  loading: boolean = false;
  @Input() pejaSubscriber: PejaSubscriber | undefined = undefined;
  @Input() managementPath: string | undefined = undefined;

  constructor(
    private topicService: TopicsService,
    private subscriptionService: SubscriptionService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {}

  subscriberForm = this.formBuilder.group({
    email: [
      this.pejaSubscriber?.email ?? '',
      [Validators.email, Validators.required],
    ],
  });

  getTopics(): void {
    this.topicService.getTopics().subscribe((topics) => {
      this.topics = topics;
      if (this.pejaSubscriber) {
        const subscriberTopics: string[] =
          this.pejaSubscriber.topics.split(',');
        this.selectedTopics = this.topics.filter((topic) =>
          subscriberTopics.includes(topic.name)
        );
      }
    });
  }

  onSelect(topic: Topic): void {
    if (this.selectedTopics.includes(topic)) {
      this.selectedTopics = this.selectedTopics.filter(
        (selectedTopic) => selectedTopic !== topic
      );
    } else {
      this.selectedTopics.push(topic);
    }
  }

  hasTopic(topicId: number): boolean {
    return this.selectedTopics.map((topic) => topic.id).includes(topicId);
  }

  onSubmit() {
    if (this.selectedTopics.length < 1) {
      this.failedFields.push('topics');
    }
    if (this.subscriberForm.value != null && this.subscriberForm.valid) {
      if (this.selectedTopics.length < 1) {
        return;
      }
      this.loading = true;
      const topics: string = this.selectedTopics
        .map((topic) => topic.name)
        .toString();
      const subscriberEmail = this.subscriberForm.value.email;
      const subscriber: PejaSubscriber = {
        id: undefined,
        topics: topics,
        email: subscriberEmail!,
        status: SubscriberStatus.ACTIVE,
        sha256: undefined,
      };
      if (this.managementPath === undefined) {
        this.addSubscriber(subscriber);
      } else if (this.managementPath === 'unsubscribe') {
        this.unsubscribe(subscriber);
      } else if (this.managementPath === 'edit') {
        this.editSubscriber(subscriber);
      }
    } else {
      this.failedFields.push('email');
    }
  }

  addSubscriber(subscriber: PejaSubscriber): void {
    this.subscriptionService.addSubscriber(subscriber).subscribe((data) => {
      if (data.sha256 !== null) {
        this.loading = false;
        this.router.navigate([`/${data.sha256}/welcome`]);
        this.subscriberForm.reset();
      }
    });
  }

  unsubscribe(subscriber: PejaSubscriber): void {
    this.subscriptionService.unsubscribe(subscriber).subscribe((data) => {
      this.loading = false;
      //TODO: Push to unsubscribe success page
      console.log('Success', data);
    });
    this.subscriberForm.reset();
  }

  editSubscriber(subscriber: PejaSubscriber): void {
    this.subscriptionService.editSubscriber(subscriber).subscribe((data) => {
      this.loading = false;
      //TODO: Push to success page
      console.log('Success', data);
    });
    this.subscriberForm.reset();
  }

  ngOnInit(): void {
    this.getTopics();
  }
}
