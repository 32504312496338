<div id="peja-faqs">
    <div id="peja-faqs-title">
        <div id="peja-title-and-subtitle-column">
            <h1>FAQ's</h1>
            <p>Here are some frequently asked questions about Peja.
                If you are unable to find what you are looking for, please do not hesitate
                to contact us at sondersoftwares&#64;gmail.com</p>

        </div>
    </div>

    <div id="peja-faqs-body" *ngIf="faqs.length > 0" >
        <div class="accordion accordion-flush" *ngFor="let faq of faqs; index as i" [id]="'faqAccordion' + i">
            <div class="accordion-item">
                <h2 class="accordion-header">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                    [attr.data-bs-target]="'#collapse' + i" aria-expanded="true" [attr.aria-controls]="'collapse' + i">
                        {{ faq.question }}
                    </button>
                </h2>
                <div [id]="'collapse' + i" class="accordion-collapse collapse show" [attr.data-bs-parent]="'#faqAccordion' + i">
                    <div class="accordion-body">
                        <p [innerHTML]="faq.answer | newlineToBr"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div id="peja-membership-management-loader" *ngIf="faqs.length === 0"
        class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></div>
</div>