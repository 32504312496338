import { Routes } from '@angular/router';
import { SubscriptionComponent } from './subscription/subscription.component';
import { SubscriberManagementComponent } from './subscriber-management/subscriber-management.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { FaqsComponent } from './faqs/faqs.component';

export const routes: Routes = [
    { path: '', component: SubscriptionComponent },
    { path: 'help/faqs', component: FaqsComponent },
    { path: ':sha256/subscription/:managementPath', component: SubscriberManagementComponent },
    { path: ':sha256/welcome', component: WelcomeComponent },
    { path: '**', pathMatch: 'full',  component: PageNotFoundComponent }
  ];
