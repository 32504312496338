<div class="container">
  <div class="container">
    <footer class="py-5">
      <div class="row">
        <div class="col-6 col-md-2 mb-3">
          <h5>Membership</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><a href="#" class="nav-link p-0 text-body-secondary">Home</a></li>
            <li class="nav-item mb-2"><a href="https://kalela.github.io/" target="_blank" class="nav-link p-0 text-body-secondary">About Us</a></li>
          </ul>
        </div>
  
        <div class="col-6 col-md-2 mb-3">
          <h5>Help</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><a href="/help/faqs" class="nav-link p-0 text-body-secondary">FAQ's</a></li>
            <li class="nav-item mb-2"><a href="mailto:sondersoftwares@gmail.com" class="nav-link p-0 text-body-secondary">Email</a></li>
            <li class="nav-item mb-2"><a href="tel:+254717174985" class="nav-link p-0 text-body-secondary">Phone</a></li>
          </ul>
        </div>
  
        <div class="col-6 col-md-2 mb-3">
          <h5>Contact Us</h5>
          <ul class="nav flex-column">
            <li class="nav-item mb-2"><a href="mailto:sondersoftwares@gmail.com" class="nav-link p-0 text-body-secondary">Email</a></li>
            <li class="nav-item mb-2"><a href="tel:+254717174985" class="nav-link p-0 text-body-secondary">Phone</a></li>
            <li class="nav-item mb-2"><a href="https://kalela.github.io/" target="_blank" class="nav-link p-0 text-body-secondary">About Us</a></li>
          </ul>
        </div>
      </div>
  
      <div class="d-flex flex-column flex-sm-row justify-content-between py-4 my-4 border-top">
        <p>© 2024 Sonder Softwares, Inc. All rights reserved.</p>
        <ul class="list-unstyled d-flex">
          <li class="ms-3"><a class="link-body-emphasis" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#twitter"></use></svg></a></li>
          <li class="ms-3"><a class="link-body-emphasis" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#instagram"></use></svg></a></li>
          <li class="ms-3"><a class="link-body-emphasis" href="#"><svg class="bi" width="24" height="24"><use xlink:href="#facebook"></use></svg></a></li>
        </ul>
      </div>
    </footer>
  </div>
</div>