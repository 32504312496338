<form [formGroup]="subscriberForm" (ngSubmit)="onSubmit()">
  <div class="row row-cols-1 row-cols-md-4 g-4">
    <div class="col" *ngFor="let topic of topics" id="topic-column">
      <div
        id="topic-card"
        class="card"
        [ngClass]="{ 'card-selected': hasTopic(topic.id) }"
        (click)="onSelect(topic)"
      >
        <div class="card-body">
          <h5 class="card-title">{{ topic.displayName }}</h5>
          <input
            class="form-check-input"
            type="checkbox"
            [checked]="hasTopic(topic.id)"
            id="topic-checkbox"
          />
          <p class="card-text">{{ topic.description }}</p>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="
      failedFields.includes('topics')
    "
    class="alert alert-danger"
  >
    <div>Please select at least one topic</div>
  </div>

  <div id="simple-form">
    <div class="d-flex flex-column flex-sm-row gap-2">
      <div class="form-floating col-lg" *ngIf="!loading && managementPath !== 'edit'">
        <input
          type="email"
          class="form-control"
          id="floatingInput"
          placeholder="name@example.com"
          formControlName="email"
        />
        <div
          *ngIf="
            failedFields.includes('email')
          "
          class="alert alert-danger"
        >
          <div>A valid email is required</div>
        </div>
        <label for="floatingInput">Email address</label>
      </div>
      <button
        *ngIf="!pejaSubscriber"
        class="btn btn-primary btn-lg"
        type="submit"
        id="subscribe-button"
        [disabled]="!subscriberForm.valid"
      >
        <span *ngIf="!loading">Subscribe</span>
        <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
      <button
        *ngIf="pejaSubscriber"
        class="btn btn-primary btn-lg"
        type="submit"
        id="subscribe-button"
      >
      <span *ngIf="!loading && managementPath === 'unsubscribe'">Unsubscribe</span>
      <span *ngIf="!loading && managementPath === 'edit'">Edit Topics</span>
      <span *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      </button>
    </div>
  </div>
</form>
