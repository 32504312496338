import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newlineToBr',
  standalone: true
})
export class NewlineToBrPipe implements PipeTransform {

  transform(value: string): string {
    console.log('in newline pipe value', value);
    if (!value) return value;
    return value.replaceAll('\\n', '<br>');
  }

}
