import { Component } from '@angular/core';
import { TopicsService } from '../domain/services/topics.service';
import Topic from '../model/topic';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  topics: Topic[] = [];
  constructor(private topicService: TopicsService,) {
    
  }

  getTopics(): void {
    this.topicService.getTopics().subscribe((topics) => (this.topics = topics));
  }

  ngOnInit(): void {
    this.getTopics();
  }

}
