import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import PejaSubscriber from '../../model/subscriber';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubscriptionService {
  url = `${environment.apiUrl}/subscribers`;
  constructor(private http: HttpClient) {}

  addSubscriber(subscriber: PejaSubscriber): Observable<PejaSubscriber> {
    console.log('Environment:', environment);
    return this.http
      .post<PejaSubscriber>(this.url, subscriber)
      .pipe(catchError(this.handleError<PejaSubscriber>('addSubscriber', subscriber)));
  }

  unsubscribe(subscriber: PejaSubscriber): Observable<PejaSubscriber> {
    return this.http
        .patch<PejaSubscriber>(this.url, { 'id': subscriber.id })
        .pipe(catchError(this.handleError<PejaSubscriber>('unsubscribe', subscriber)));
  }

  editSubscriber(subscriber: PejaSubscriber): Observable<PejaSubscriber> {
    return this.http
        .post<PejaSubscriber>(this.url, subscriber)
        .pipe(catchError(this.handleError<PejaSubscriber>('addSubscriber', subscriber)));
  }

  fetchSubscriber(sha256: string): Observable<PejaSubscriber> {
    return this.http
      .get<PejaSubscriber>(`${this.url}/${sha256}`)
      .pipe(catchError(this.handleError<PejaSubscriber>('getSubscriber')));
  }

  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      // this.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
