import { Component } from '@angular/core';
import { SubscriptionFormComponent } from '../subscription-form/subscription-form.component';
import { FooterComponent } from '../footer/footer.component';

@Component({
  selector: 'app-subscription',
  standalone: true,
  imports: [SubscriptionFormComponent, FooterComponent],
  templateUrl: './subscription.component.html',
  styleUrl: './subscription.component.scss'
})
export class SubscriptionComponent {

}
