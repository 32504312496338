import { Component, Input } from '@angular/core';
import { SubscriptionFormComponent } from '../subscription-form/subscription-form.component';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionService } from '../domain/services/subscription.service';
import PejaSubscriber from '../model/subscriber';
import {CommonModule, NgIf} from "@angular/common";
import {FooterComponent} from "../footer/footer.component";

@Component({
  selector: 'app-subscriber-management',
  standalone: true,
  imports: [SubscriptionFormComponent, CommonModule, NgIf, FooterComponent],
  templateUrl: './subscriber-management.component.html',
  styleUrl: './subscriber-management.component.scss',
})
export class SubscriberManagementComponent {
  pejaSubscriber: PejaSubscriber | undefined = undefined;
  managementPath: string | undefined = undefined;

  constructor(
    private route: ActivatedRoute,
    private subscriptionService: SubscriptionService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      var sha256 = params.get('sha256')!;
      this.managementPath = params.get('managementPath')!;
      this.subscriptionService
        .fetchSubscriber(sha256)
        .subscribe((subscriber) => {
          this.pejaSubscriber = subscriber;
        });
    });
  }
}
